import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// signup for action
export const userSighUpAction = createAsyncThunk("userSighUpAction/user/signup", async (data) => {
    const response = await api.post("/user/signup", data);
    return response.data;
}
);

// otp-verification
export const userOtpVerification = createAsyncThunk("userOtpVerification/user/verify-email", async (data) => {
    const response = await api.post("/user/verify-email", data)
    return response.data
})

// resend otp verification
export const userResendOtpVerification = createAsyncThunk("userResendOtpVerification/user/resend-otp", async (data) => {
    const response = await api.post("/user/resend-otp", data)
    return response.data
})

// profile upload action
export const userProfileDetailAction = createAsyncThunk("userProfileDetailAction/user/profile", async (data) => {
    const response = await api.put("/user/profile", data)
    return response.data
})

// login action
export const userLoginAction = createAsyncThunk("userLoginAction/user/login", async (data) => {
    const response = await api.post("/user/login", data)
    return response.data
})

// forgot-password action
export const forgotPasswordAction = createAsyncThunk("forgotPasswordAction/user/forgot-password", async (data) => {
    const response = await api.post("/user/forgot-password", data)
    return response.data
})

// forgot-password-otp-verify
export const forgotPasswordOtpVerifyAction = createAsyncThunk("forgotPasswordOtpVerifyAction/user/forgot-otp-verify", async (data) => {
    const response = await api.post("/user/forgot-otp-verify", data)
    return response.data
})

// reset-password
export const resetPassword = createAsyncThunk("resetPassword/user/reset-password", async (data) => {
    const response = await api.post("/user/reset-password", data)
    return response.data
})

// change password
export const changePassword = createAsyncThunk("changePassword/user/change-password", async (data) => {
    const response = await api.patch("/user/change-password", data)
    return response.data
})

// get profile data action
export const getProfileDetails = createAsyncThunk("getProfileDetails/user/profile", async (data) => {
    const response = await api.get("/user/profile", data)
    return response.data
})

export const getNotificationListAction = createAsyncThunk("getNotification/list", async (data) => {
    const response = await api.get("/user/notification", data)
    return response.data
})
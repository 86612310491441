import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Icons from "../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { dashboardCardAction } from "../Redux/Actions/shopAction";
import NotificationComp from "../Components/common/NotificationComp";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { shopDashboardCard } = useSelector((state) => state?.shopAuthData);

  useEffect(() => {
    dispatch(dashboardCardAction());
  }, []);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.dashboardIcon}Dashboard</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>
      <Container fluid className="p-0">
        <div className="dashboard-items pb-0">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <Row>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalOrder}</div>
                    <div className="text-part">
                      <h2>
                        {shopDashboardCard?.confirmed_orders +
                          shopDashboardCard?.pending_orders}
                      </h2>
                      <p>Total Orders</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalDeliveredIcon}</div>
                    <div className="text-part">
                      <h2>{shopDashboardCard?.delivered_orders}</h2>
                      <p>Total Delivered</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalCanceledIcon}</div>
                    <div className="text-part">
                      <h2>{shopDashboardCard?.cancelled_orders} </h2>
                      <p>Total Canceled </p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalRevenueIcon}</div>
                    <div className="text-part">
                      <h2>${0} </h2>
                      <p>Total Revenue</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xxl={6} xl={6} lg={6} className="mt-4">
              <div className="dummy-graph">
                {/* <img src={require("../Assets/Images/card_chart_order.png")} /> */}
                <img src={require("../Assets/Images/pie_chart.png")} />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} className="mt-4">
              <div className="dummy-graph">
                <img src={require("../Assets/Images/card_chart_order.png")} />
                {/* <img src={require("../Assets/Images/pie_chart.png")} /> */}
              </div>
            </Col>
          </Row>

          <Row className="dash-bottom">
            <Col xxl={8} xl={8} lg={8}>
              <div className="dummy-graph">
                <img src={require("../Assets/Images/card_total_revenue.png")} />
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className="dummy-graph">
                <img src={require("../Assets/Images/card_customer_map.png")} />
              </div>
            </Col>

            {/* <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Users</h6>
                <Users />
              </div>
            </Col> */}
            {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
            {/* <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Total Orders</h6>
                <Orders />
              </div>
            </Col> */}
          </Row>
        </div>
        <div className="dashboard-items">
          <Row>
            <Col lg={12}>
              <div className="cake-review-box">
                <h2>Customer Review</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adip</p>
                <Col lg={12}>
                  <Row>
                    <Col lg={6}>
                      <div className="cake-review-card">
                        <div className="cake-review-right">
                          <div className="user-review-pro">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="">
                              <h3>Jons Sena</h3>
                              <p>2 days ago</p>
                            </div>
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text
                          </p>
                          <div className="manage-shop-star">
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            <p>4.5</p>
                          </div>
                        </div>
                        <div className="cake-review-left">
                          <img src={require("../Assets/Images/user.png")} />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="cake-review-card">
                        <div className="cake-review-right">
                          <div className="user-review-pro">
                            <img src={require("../Assets/Images/user.png")} />
                            <div className="">
                              <h3>Jons Sena</h3>
                              <p>2 days ago</p>
                            </div>
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text
                          </p>
                          <div className="manage-shop-star">
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            {Icons.StarIcon}
                            <p>4.5</p>
                          </div>
                        </div>
                        <div className="cake-review-left">
                          <img src={require("../Assets/Images/user.png")} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList, orderDetailParticularById, orderPickedUpAction } from "../Redux/Actions/orderAction";
import OrderAcceptRejectModal from "../Components/Layout/Modals/OrderAcceptRejectModal";
import { Form } from "react-bootstrap";
import { formatPhoneNumber, fullName } from "../utils/commonFunction";
import toast from "react-hot-toast";
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageOrdersDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { orderDetailById } = useSelector((state) => state?.orderAuthData)
  const [modalShow, setModalShow] = useState(false);
  const [modalState, setModalState] = useState({
    id: "",
    type: ""
  });

  console.log("orderDetailById", orderDetailById)


  useEffect(() => {
    if (id) {
      dispatch(orderDetailParticularById({ id }))
    }
  }, [id, dispatch])

  const handleConfirm = (type) => {
    let newValue = {
      id: id,
      type: type
    }
    setModalState({ ...modalState, ...newValue })
    setModalShow(true)
  }

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageOrdersIcon}Manage Orders</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>
      <div className="dashboard-items p-0">
        <Link to="/ManageOrders?status=1">{Icons.BackIcon}</Link>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row>
            <Col lg={12}>
              <div className="manage-orders-detail">
                <div className="orders-detail-box-top">
                  <div className="order-no">
                    <h2>
                      Order No. <span>{orderDetailById?.order_no || 'N/A'}</span>
                    </h2>

                    <Link className={`order-status-actn ${orderDetailById?.order_status === 1 ? ` accepted` : orderDetailById?.order_status === 2 ? ` decline` : ""}`}>
                      {
                        orderDetailById?.order_status === 0 ? "Pending" : orderDetailById?.order_status === 1 ? `Accepted` : orderDetailById?.order_status === 2 ? `Decline` : "N/A"
                      }
                    </Link>
                  </div>
                </div>
                <div className="order-manage-use">
                  <div className="use-name">
                    <h2>
                      {fullName(orderDetailById?.user_id?.first_name, orderDetailById?.user_id?.last_name)}
                    </h2>
                    <div>
                      <p>Payment: <span className="text-danger">${orderDetailById?.items_total_price || '0'}</span> <span className="text-success">{orderDetailById?.payment_status === 1 ? `Paid` : orderDetailById?.payment_status === 2 ? `Failed` : `Pending`}</span></p>
                    </div>
                  </div>
                  <div className="orders-detail-box-top">
                    {
                      orderDetailById?.order_status == 0 &&
                      <>
                        <div className="action-btn">
                          <button type="button" className="btn-fill" onClick={() => handleConfirm("accept")}>
                            Accept Order
                          </button>
                          <button type="button" className="btn-outline" onClick={() => handleConfirm("reject")}>
                            Decline Order
                          </button>
                        </div>
                      </>
                    }
                    {
                      orderDetailById?.order_status == 1 && orderDetailById?.partner_id &&
                      <>
                        <div className="order-detail-pre">
                          <p>Delivery Partner</p>
                          <p>{fullName(orderDetailById?.partner_id?.first_name, orderDetailById?.partner_id?.last_name)}</p>
                          <div>Ph: {formatPhoneNumber(orderDetailById?.partner_id?.country_code, orderDetailById?.partner_id?.phone_no)}</div>
                        </div>
                      </>
                    }

                  </div>
                </div>
                <Row className="mt-4">
                  <div className="delivery-details">
                    <p>Products {orderDetailById?.order_items?.length != 0 && `( ${orderDetailById?.order_items?.length} )`}</p>
                  </div>
                  {
                    Array.isArray(orderDetailById?.order_items) && orderDetailById?.order_items?.length > 0 &&
                    orderDetailById?.order_items?.map((res) => {
                      return <>

                        <Col lg={3} key={res?._id}>
                          <div className="order-card-box">
                            <div className="">
                              <img src={res?.product_id?.images?.length > 0 ? `${process.env.REACT_APP_BASE_URL}public/${res?.product_id?.images[0]}` : require("../Assets/Images/order-image.png")} />
                              <div className="">
                                <h2>{res?.product_id?.name || 'N/A'}</h2>
                                <p>Size: {res?.size_id?.name || 'N/A'}</p>
                                <p>Quantity: {res?.quantity || '0'}</p>
                              </div>
                            </div>
                            <hr />
                            <p>Shop : <span><b>Macaron Bakers</b></span></p>
                            <p>{res?.request?.length > 50 ? `${res?.request.slice(0, 50)}...` : res?.request}</p>
                          </div>
                        </Col>
                      </>
                    })
                  }
                </Row>
                <Row className="mt-4">
                  <Col lg={6}>
                    <div className="delivery-details">
                      <p>Address</p>
                      <div className="delivery-details-box">
                        <div>
                          {orderDetailById?.address_id?.full_address || 'N/A'}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* OrderAcceptRejectModal */}
      <OrderAcceptRejectModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalState}
        page="Details"
      />

    </Layout>
  );
}

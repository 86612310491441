import moment from "moment/moment";
import { useLocation } from "react-router-dom";

const convertTimeToUTC = (time) => {
    let utcTime = moment(time, "HH:mm").utc().format("HH:mm");
    return utcTime;
};

const serialNumber = (currentPage, limit, index) => {
    return (currentPage - 1) * limit + index + 1;
}

const convertUTCToLocal = (utcTime) => {
    let localTime = moment.utc(utcTime, "HH:mm").local().format("HH:mm");
    return localTime;
};

const convertUTCToLocalWithAMPM = (utcTime) => {
    let localTime = moment.utc(utcTime, "HH:mm").local().format("hh:mm A");
    return localTime;
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const capitalizeFirstLetter = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return `N/A`
    }
}

const fullName = (firstName, lastName) => {
    let name = firstName ? `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}` : 'N/A'
    return name;
}

const formatPhoneNumber = (countryCode, phoneNumber) => {
    if (countryCode) {
        if (!countryCode?.startsWith('+')) {
            countryCode = '+' + countryCode;
        }
    }
    return `${phoneNumber ? `${countryCode || ""} ${phoneNumber}` : 'N/A'}`;
}

const constructQueryString = (obj) => {
    if (obj) {
        const queryString = Object.keys(obj)
            .filter(key => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
        return queryString;
    } else {
        return ""
    }
}

const getOrderStatus = (status) => {
    if (status == 1) {
        return 'Confirmed'
    } else if (status == 2) {
        return 'Picked'
    } else if (status == 3) {
        return 'Out-for-delivery'
    } else if (status == 4) {
        return 'Delivered'
    } else if (status == 5) {
        return 'Cancelled'
    } else if (status == 6) {
        return 'Payment-not-succeded'
    } else {
        return 'N/A'
    }
}

const downloadURL = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export {
    convertTimeToUTC,
    convertUTCToLocal,
    useQuery,
    fullName,
    formatPhoneNumber,
    capitalizeFirstLetter,
    serialNumber,
    constructQueryString,
    convertUTCToLocalWithAMPM,
    getOrderStatus,
    downloadURL
}
import { createSlice } from "@reduxjs/toolkit";
import { getBankDetails, getInvoiceList, getOrderList, getWalletList, orderDetailParticularById } from "../Actions/orderAction";


const initialState = {
  loading: false,
  orderList: [],
  invoiceList: [],
  walletList: [],
  error: false,
  orderDetailById: [],
  bankDetails: {}
};

const orderSlice = createSlice({
  name: "orderAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderList.fulfilled, (state, { payload }) => {
        state.orderList = payload.data
        state.loading = false;
      })
      .addCase(getOrderList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // order detail by id 
      .addCase(orderDetailParticularById.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderDetailParticularById.fulfilled, (state, { payload }) => {
        state.orderDetailById = payload.data
        state.loading = false;
      })
      .addCase(orderDetailParticularById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Invoice and wallet
      .addCase(getInvoiceList.fulfilled, (state, { payload }) => {
        state.invoiceList = payload.data
        state.loading = false;
      })
      .addCase(getWalletList.fulfilled, (state, { payload }) => {
        state.walletList = payload.data
        state.loading = false;
      })
      .addCase(getBankDetails.fulfilled, (state, { payload }) => {
        state.bankDetails = payload.data
        state.loading = false;
      });
  },
});


export default orderSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./Reducers/userSlice";
import shopAuthReducer from "./Reducers/shopSlice"
import productAuthReducer from "./Reducers/productSlice"
import orderAuthReducer from "./Reducers/orderSlice"


export const store = configureStore({
  reducer: {
    userAuthData: userAuthReducer,
    shopAuthData: shopAuthReducer,
    productAuthData: productAuthReducer,
    orderAuthData: orderAuthReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import NotificationComp from "../Components/common/NotificationComp";

export default function Support() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.SupportIcon}Support</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>
      <Container fluid className="mt-5 p-0">
        <div className="dashboard-items">
          <Row className="justify-content-center ">
            <Col lg={8} className="mx-auto">
              <div className="support-chat-box-main">
                <div className="support-inner">
                  {/* <h2>Chat</h2> */}
                  <div className="support-msg-box">
                    <div className="user-box">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box my-side-chat">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                  <div class="send-box">
                    <div class="mb-3 input-group chat-msg-bar">
                      <input
                        placeholder="Type message.."
                        aria-label="send"
                        aria-describedby="basic-addon2"
                        name="message"
                        type="text"
                        class="send-feild form-control"
                        value=""
                      />
                      <button
                        class="input-group-text"
                        id="basic-addon2"
                        type="submit"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none"
                        >
                          <path
                            d="M0.523716 17.2572C0.110234 16.7692 -0.162488 16.0958 0.108768 15.3141C0.544243 14.0606 1.01344 12.8205 1.47531 11.576C1.69671 10.9782 1.93864 10.3879 2.16005 9.79155C2.20697 9.66398 2.26562 9.62838 2.39611 9.62986C4.24651 9.63431 6.09838 9.63134 7.94879 9.63431C8.17312 9.63431 8.3696 9.58536 8.51476 9.39994C8.67018 9.20266 8.70244 8.98609 8.60273 8.75765C8.50743 8.5396 8.33588 8.41055 8.09834 8.38533C8.02796 8.37791 7.95612 8.38088 7.88574 8.38088C6.06026 8.38088 4.23479 8.3794 2.41077 8.38533C2.26268 8.38533 2.19964 8.33786 2.14832 8.20139C1.4929 6.46736 0.828695 4.73629 0.174749 3.00225C-0.361897 1.57972 0.531047 0.117134 1.99729 0.00588286C2.4489 -0.0282342 2.86531 0.0874672 3.25533 0.315903C7.12476 2.57505 10.9956 4.83271 14.8651 7.09185C16.1803 7.86022 16.3914 9.58388 15.2932 10.6059C15.1671 10.7246 15.0205 10.8254 14.8709 10.913C11.0015 13.1736 7.13209 15.4327 3.2612 17.6904C2.36239 18.211 1.33308 18.0805 0.523716 17.2572Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}

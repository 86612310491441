import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { Decryptedid } from "../utils/Becryprt";
import { Formik, Form as FormikForm, FieldArray, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { shopDetailParticularById, shopOpeningTiming } from "../Redux/Actions/shopAction";
import toast from "react-hot-toast";
import * as Yup from 'yup';
import { convertTimeToUTC, convertUTCToLocal } from "../utils/commonFunction";
import { ProgressBar } from "react-bootstrap";
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageShopOpeningHours({ formData, prevStep }) {
  const { id } = useParams();
  const shopId = id && Decryptedid(atob(id));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getShopDetail = useSelector((state) => state.shopAuthData.shopDetailById);

  useEffect(() => {
    dispatch(shopDetailParticularById(shopId));
  }, [dispatch, shopId]);

  const allDaysValues = [
    { day: "Monday", from: "", to: "" },
    { day: "Tuesday", from: "", to: "" },
    { day: "Wednesday", from: "", to: "" },
    { day: "Thursday", from: "", to: "" },
    { day: "Friday", from: "", to: "" },
    { day: "Saturday", from: "", to: "" },
    { day: "Sunday", from: "", to: "" }
  ];

  const initialValues = {
    opening_hours: allDaysValues.map((item) => {
      const leftDays = getShopDetail?.opening_hours?.find(
        (ele) => ele?.day === item?.day
      );
      if (leftDays) {
        return {
          ...item,
          ...leftDays,
          from: convertUTCToLocal(leftDays?.from),
          to: convertUTCToLocal(leftDays?.to),
          isAvailable: !!(leftDays?.from && leftDays?.to)
        };
      }
      return { ...item, isAvailable: false };
    })
  };

  const validationSchema = Yup.object().shape({
    opening_hours: Yup.array()
      .of(
        Yup.object().shape({
          from: Yup.string()
            .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format')
            .nullable(),
          to: Yup.string()
            .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format')
            .nullable(),
        })
      )
      .test('at-least-one', 'At least one day must have both "from" and "to" times filled', (value) => {
        return value && value.some(day => day.from && day.to);
      })
  });

  const handleSubmit = (values) => {
    console.log("Submitted values:", values); // Check the structure of submitted values

    const hours = values.opening_hours
      .filter(({ from, to, isAvailable }) => {
        // Only include days that are available and have valid 'from' and 'to' times
        return isAvailable && from && to;
      })
      .map(({ day, from, to, _id }) => {
        // Ensure 'from' and 'to' are strings and not objects
        const result = {
          day,
          from: from ? convertTimeToUTC(from) : "",
          to: to ? convertTimeToUTC(to) : "",
        };
        if (_id) {
          result._id = _id;
        }
        return result;
      });

    console.log("Filtered hours:", hours); // Log the filtered result before dispatching

    dispatch(shopOpeningTiming({ id: shopId, data: { "opening_hours": hours } })).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message);
        navigate("/ManageShop");
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  console.log("getShopDetail", getShopDetail)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageShopIcon}
              {
                getShopDetail?.opening_hours?.length > 0 ?
                  `Edit Shop Opening Hours` : `Add Shop`
              } </h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row className="mt-5">
            <Col lg={12} className="m-auto">
              {
                !getShopDetail?.opening_hours?.length &&
                <>
                  <div className="membership-progress">
                    <div className="step-membership">
                      <ProgressBar now={100} />
                      <p>
                        <span>Step 4:</span> Add Shop Opening Hours
                      </p>
                    </div>
                  </div>
                </>
              }

              <div className="login-cmn-box ad-inner-width select-mainplan-box">
                <h2 className="text-center"> {getShopDetail?.opening_hours?.length > 0 ? `Edit` : `Add`} Shop Opening Hours</h2>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                >
                  {({ values, handleChange, setFieldValue, errors, touched }) => ( // Add setFieldValue here
                    <FormikForm>
                      <Row className="align-items-center mb-3">
                        <Col lg={12} className="mx-auto opening-hours-box">
                          <FieldArray name="opening_hours">
                            {({ insert, remove, push }) => (
                              <div>
                                {values.opening_hours.map((day, index) => (
                                  <React.Fragment key={index}>
                                    <Row className="align-items-center mb-3">
                                      <Col lg={2} className="d-flex week gap-2 ">
                                        <p>{day.day}</p>
                                        <div className="shop-availability-toggle">
                                          <Form.Check
                                            type="switch"
                                            checked={values.opening_hours[index].isAvailable}
                                            onChange={(e) => {
                                              const updatedHours = [...values.opening_hours];
                                              updatedHours[index].isAvailable = e.target.checked;
                                              setFieldValue('opening_hours', updatedHours); // Update field value
                                            }}
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={5}>
                                        <Form.Control
                                          as="input"
                                          name={`opening_hours.${index}.from`}
                                          type="time"
                                          value={values.opening_hours[index].from}
                                          onChange={handleChange}
                                          placeholder="From"
                                          disabled={!values.opening_hours[index].isAvailable}
                                        />
                                        {/* <ErrorMessage name={`opening_hours.${index}.from`} component="div" className="text-danger" /> */}
                                      </Col>
                                      <Col lg={5}>
                                        <Form.Control
                                          as="input"
                                          name={`opening_hours.${index}.to`}
                                          type="time"
                                          value={values.opening_hours[index].to}
                                          onChange={handleChange}
                                          placeholder="To"
                                          disabled={!values.opening_hours[index].isAvailable}
                                        />
                                        {/* <ErrorMessage name={`opening_hours.${index}.to`} component="div" className="text-danger" /> */}
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>

                      {errors.opening_hours && touched.opening_hours && (
                        <div className="text-danger text-center mb-3">
                          {errors.opening_hours}
                        </div>
                      )}
                      <button type="submit" className="submit filldetails-btn">
                        Update
                      </button>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
